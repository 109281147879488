import React ,{useState,useEffect,useContext} from "react";
import { Container, Row, Col, NavDropdown} from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from "react-router-dom";
import navBarCss from './../css/navBarCss.module.css';
import { apiBaseURL, apiURL } from './../../../constant';
import { Contaxt } from "../../../Store";
const Navigation = () => {
  const pathName = useLocation().pathname;
  const {menuItems} = useContext(Contaxt);
  //========================================================================
//===================================================================

  return(
    <>
      <Container fluid>
        <Row>
          <Col className="navigation">
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">               

                <ul className="navbar-nav">
                {menuItems.map( (item, index)=>{   
                    //let slug = item.slug=='home' ? "/" : item.slug;
                    
                    if(item.menu_title=="Career"){
                      return(                     
                        <li key={index}>
                            <NavDropdown title=" Career" id="basic-nav-dropdown">
                             <NavDropdown.Item className="border-bootem p-1"><Link to="/career"> Job Opportunities  </Link> </NavDropdown.Item>
                             <NavDropdown.Item className="p-1"><Link to="/shareCV"> Share Resume </Link> </NavDropdown.Item>
                            </NavDropdown>
                        </li>                 
                      );

                    }else{
                      // console.log("sdjkfksdj fksjdkfjlkdsjfksdf")
                      // console.log(pathName)
                      // console.log(item.slug, index)
                      return(                     
                        <li key={index}>
                        {item.urlType=='internal'?
                          <Link className={pathName==item.slug?navBarCss.ActiveButton:null} to={item.slug} >{item.menu_title}</Link>:
                        <a href={item.slug} target="_blank" onClick={()=>{console.log("lkdsfs")}}> {item.menu_title} </a>
                        }
                        </li>                 
                      );

                    }
                    
                  } )}
                   {/* <li>
                   <NavDropdown title=" Career" id="basic-nav-dropdown">
                    <NavDropdown.Item className="border-bootem p-1"><Link to="/career"> Current Opening </Link> </NavDropdown.Item>
                    <NavDropdown.Item className="p-1"><Link to="#"> Share Resume </Link> </NavDropdown.Item>
                  </NavDropdown>
                  </li> */}

                  {/* <li> <Link className={pathName=='/'?navBarCss.ActiveButton:null} to="/">Home</Link></li>
                  <li> <Link className={pathName=='/aboutus'?navBarCss.ActiveButton:null} to="/aboutus">About</Link></li>
                  <li> <Link className={pathName=='/contact'?navBarCss.ActiveButton:null} to="/contactus">Contact</Link></li> */}
                </ul>
                </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
        
     </Container>
    </>
  );
};
export default Navigation;


