import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col} from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import { Contaxt } from '../../../Store';
import Helmet from "react-helmet";

const Thankyou = () => {

  const [queryString] = useSearchParams();
  const [orderData, setOrderData] = useState({
    type:"",
    cnfData:{}
  });
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  const navigate = useNavigate();
  const {setCartItemNum} = useContext(Contaxt);
  useEffect(() => { 
    let type = queryString.get("type")
    let data = queryString.get("data")
    console.log(type);
    console.log(data);
    
    if(type==null || data==null){
      navigate('/');
      return;
    }
    try{
      let cnfData = JSON.parse(atob(data));
      setOrderData({
        type:type,
        cnfData:cnfData,
      })
        
      if(type=="offline" && cnfData.payment_status=="Pending"){
        setCartItemNum(0);
      } else if(type=="online" && cnfData.payment_status=="Success"){
        setCartItemNum(0);
      }
      getSeoData();
    } catch(err) {
      console.log(err);
      navigate('/');
      return;
    }
    
    AOS.init({
      offset: 300,
      duration:1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.thankyouSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"thankyou"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
    <>
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
      <div className='order_boxbg'>
      <Container>
        <Row>
           <Col md={1}><br/></Col>
          <Col md={5} sm={12} ><br/>
          <div className="order-box" data-aos="zoom-in-right">
              <Row className='back-ship'>
                <Col>
                <h1 className='thanku-hedi'> Thank You For Your Order!</h1>
                </Col>
              </Row>
              <div className='thanks-detail'>
                <Row>
                    <Col md={12} sm={12}>
                     <h2> Order Confirmation </h2>
                     <div className='address-thx'> Payment Method : <b> {orderData.cnfData?.payment_method} </b> </div>
                     <div className='address-thx'> Order Number : <b> {orderData.cnfData?.order_id} </b> </div>
                     <div className='address-thx'> Amount  : <b> {orderData.cnfData?.amount} </b> </div>
                     <div className='address-thx'> Payment status :  <b> {orderData.cnfData?.payment_status} </b> </div>
                    </Col>
                </Row>
              </div>
          </div>
          </Col>
          {orderData.type=="offline" && 
            <Col md={5} sm={12} ><br/>
            <div className="order-box" data-aos="zoom-in-right">
                <Row className='back-ship'>
                  <Col>
                  <div className='thanksp'> 
                  Please deposit your amount & send the payment receipt through email for processing your order. </div>
                  </Col>
                </Row>
                <div className='thanks-detail'>
                  <Row>
                      <Col md={12} sm={12}>
                      <h2> Bank Details: </h2>
                      <div className='address-thx'> Account Name : <b> RACHNA SAGAR PVT. LTD. </b> </div>
                      <div className='address-thx'> Bank Name : <b> HDFC BANK LTD , Daryaganj, New Delhi-110002 </b> </div>
                      <div className='address-thx'> Bank Account No : <b> 03142790000055 </b> </div>
                      <div className='address-thx'> RTGS/IFSC Code : <b> HDFC0000314 </b> </div>
                      <div className='address-thx'> SWIFT CODE : <b> HDFC INBBDEL </b> </div>
                      </Col>
                  </Row>
                </div>
            </div>
          </Col>
          }
          <Col md={1}><br/> </Col>
        </Row>
        </Container>
        <br/><br/><br/></div>
     <Footer/>
    </>
  );
};
export default Thankyou;


