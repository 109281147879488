import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from "../../../Loader";


const NewReleases = () => {
  const [newReleaseData, setNewReleaseData] = useState({
    status:"loading",
    list:[]
  })
  const [queryString] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {

    const type = queryString.get('new-release');
    if(type==null){
      navigate('/')
    } else {
      getNewReleaseData(type)
    }

    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  async function getNewReleaseData(type){
    let sendData = {
      "New_Releases":type,
      "action":"newrelease"
    }
    try {
      let response = await  fetch(apiBaseURL+apiURL.newRelease,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify(sendData)
        });
      let result = await response.json();
      if(result.status=="1"){
        setNewReleaseData((prev)=>{
          return {...prev, list:result.products, status:""}
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }

  }
  return(
    <div className="App">
      <Header/>
      <Navigation/>
        <div className="books-bg" data-aos="fade-right">
          <h1> New Releases </h1>
        </div>
          <Container className="mb-4">
            {newReleaseData.status=="loading"?
            <div style={{position:'relative', height:'200px'}}>
              <Loader width="80px" height="80px" backgroundColor="transparent"/>
            </div>:
            <Row>
              {newReleaseData.list.map((item)=>{
                return (
                <Col md={3} sm={6} key={item.productId}> 
                  <div className="book-section">
                    <Link to={`/${item.catSlug?item.catSlug:'product'}/${item.slug}?id=${item.productId}`}> <img src={item.main_image} /> </Link>
                    </div>
                    <div className="book-dis">  <Link to={`/${item.catSlug?item.catSlug:'product'}/${item.slug}?id=${item.productId}`}> {item.product_title} </Link> </div>
                  <div className="book-price">
                   ₹ {item.book_price} &nbsp; 
                    {item.book_discount_percent!=0&&
                      <strike> <span className="bookBtMrp">₹ {item.book_mrp} </span></strike>
                    }
                     {item.book_discount_percent!=0&&
                     <>
                      <span className="bookPer">  &nbsp; ({item.book_discount_percent}% off) </span>
                     </>
                     }
                  </div>
                </Col>
                )
              })}
            </Row>
            }

          </Container>
          
      <Footer/>
    </div>
  );
};
export default NewReleases;


